<template>
  <div>

    <b-card-code>
      <validation-observer ref="simple">

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(updateVendor)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >

                <label> التاريخ</label>

                <b-form-input
                  id="input-brand_name"
                  v-model="Form.date"
                  type="text"
                />
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="code"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الكود</label>

                    <b-form-input
                      id="input-code"
                      v-model="Form.code"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="العملة"
                  label-for="currency_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="currency_id"
                    rules="required"
                  >
                    <!-- <b-form-input
                      id="currency_id"
                      v-model="Form.currency_id"
                      type="currency_id"
                    /> -->
                    <v-select
                  v-model="Form.currency_id"
                  :options="optionCurrency"
                  :reduce="(val) => val.value"
                  
                />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-for="(item,i) in Form.entries" :key="i">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الدائن"
                  label-for="credit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="credit"
                    rules="required"
                  >
                    <b-form-input
                      id="credit"
                      v-model="item.credit"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="brand"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المدين</label>
                    <b-form-input
                      id="input-brand_name"
                      v-model="item.debit"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>

              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="update"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> تعديل </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </validation-observer>
    </b-card-code>

    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { required } from '@validations'
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {

  components: {
    BFormInvalidFeedback,
    required,
    vSelect,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,

    BFormGroup,
    BRow,

    BCol,
  },

  data() {
    return {
      name: '',
      number: '',
      optionCurrency: [],
      donor_id: '',
      optionsDonor: [],
      Form: {
        name: '',
        phone: '',
        email: '',
        location: '',
        notes: '',
      },
      en_name: '',
      item_category_id: '',

      searchTerm: '',
      id: '',

    }
  },

  created() {
    this.getVendor()
    this.getoptionCurrency();
  },

  methods: {
    getoptionCurrency() {
      this.optionCurrency=[]
      this.$http.get("/api/v1/currency").then((res) => {
        //console.log(res);
       
        res.data.forEach((el) => {
          this.optionCurrency.push({ value: el.id, label: `${el.name} `
 });
        });
        //console.log(this.optionCurrency);
      });
    },
    update() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              const newForm = {
  description:this.Form.description,
  currency_id: this.Form.currency_id,
  date: this.Form.date,
  entries: this.Form.entries.map(entry => {
    return {
      account_id: entry.account_id.id,
      debit: entry.debit,
      credit: entry.credit
    };
  })
};


              this.$http.put(`/api/v1/finance/transactions/${this.$route.params.id}`,newForm).then(res => {
               
      
                this.$swal({
                  title: '',
                  // text: 'تمت إضافة التعديل بانتظار موافقة المدير المالي',
                  text: res.data.message,
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },
    getVendor() {
      this.$http.get(`/api/v1/finance/transactions/${this.$route.params.id}`).then(res => {
        // console.log('/finance/transactions', res.data.data)
        this.Form = res.data.data
      })
    },

    //
  },
}
</script>

  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }

  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>
